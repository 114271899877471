import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [

  {
    path: "/",
    name: "Home",
    component: () => import("../components/HomeView.vue"),
  },
  {
    path: "/profit",
    name: "Profit",
    component: () => import("../components/Admin/ProfitView.vue"),
  },
  {
    path: "/addform",
    name: "add-form",
    component: () => import("../components/Sale/AddForm.vue"),
  },
  {
    path: "/sale/preOrder",
    name: "sale-PreOrder",
    component: () => import("../components/Sale/PreOrder/PreOrderView.vue"),
  },
  {
    path: "/Pro/ProOrder",
    name: "ProOrder",
    component: () => import("../components/Pro/ProOrder/listPreOrder.vue"),
  },
  {
    path: "/Pro/productions",
    name: "productions",
    component: () => import("../components/Pro/productions.vue"),
  },
  {
    path: "/pre-productions",
    name: "pre-productions",
    component: () => import("../components/Sale/Productions/ProductionsView.vue"),
  },
  {
    path: "/calculate/:id",
    name: "Calculate",
    component: () => import("../components/Sale/Productions/Calculate.vue"),
  },
  {
    path: "/Quotation",
    name: "Quotation",
    component: () => import("../components/Sale/Quotation/quotationView.vue"),
  },
  {
    path: "/Quotation/Print/:id", 
    name: "Print-Quotation",
    component: () => import("../components/Sale/Quotation/Print_Quotation.vue"),
    props: true, 
  },
  {
    path: "/admin/rawmatt",
    name: "rawmatt",
    component: () => import("../components/Admin/RawMattView.vue"),
  },
  {
    path: "/admin/plate",
    name: "plate",
    component: () => import("../components/Admin/PlateView.vue"),
  },
  {
    path: "/admin/print4",
    name: "print4",
    component: () => import("../components/Admin/Print4View.vue"),
  },
  {
    path: "/admin/print2",
    name: "print2",
    component: () => import("../components/Admin/Print2View.vue"),
  },
  {
    path: "/admin/hotStamp",
    name: "hotStamp",
    component: () => import("../components/Admin/HotStampView.vue"),
  },
  {
    path: "/admin/emboss",
    name: "emboss",
    component: () => import("../components/Admin/EmbossView.vue"),
  },
  {
    path: "/admin/coating",
    name: "coating",
    component: () => import("../components/Admin/CoatingView.vue"),
  },
  {
    path: "/admin/diecut",
    name: "diecut",
    component: () => import("../components/Admin/DieCutView.vue"),
  },
  {
    path: "/admin/glue",
    name: "glue",
    component: () => import("../components/Admin/GlueView.vue"),
  },
  {
    path: "/admin/bag",
    name: "bag",
    component: () => import("../components/Admin/BagView.vue"),
  },
  {
    path: "/add/product",
    name: "add-product",
    component: () => import("../components/Product/add.vue"),
  },
  {
    path: "/add/admin",
    name: "add-admin",
    component: () => import("../components/Admin/add_admin.vue"),
  },
  {
    path: "/admin/all",
    name: "all-admin",
    component: () => import("../components/Admin/UserAll/UserView.vue"),
  },
  {
    path: "/Customer",
    name: "Customer",
    component: () => import("../components/Admin/Customer/CustomerView.vue"), 
  },
  {
    path: "/Profile",
    name: "Profile",
    component: () => import("../components/Profile.vue"), 
  },
  {
    path: "/planing/orders",
    name: "Orders",
    component: () => import("../components/Plan/Orders.vue"),
  },
  {
    path: "/planing/WorkSchedule",
    name: "WorkSchedules",
    component: () => import("../components/Plan/Work/WorkSchedules.vue"),
  },
  {
    path: "/purchase/all",
    name: "Purchase-all",
    component: () => import("../components/Purchase/PurchaseView.vue"),
  },
  {
    path: "/purchase/new",
    name: "Purchase-new",
    component: () => import("../components/Purchase/PurchaseNewView.vue"),
  },
  {
    path: "/purchase/pending",
    name: "Purchase-pending",
    component: () => import("../components/Purchase/PurchasePendingView.vue"),
  },
  {
    path: "/purchase/done",
    name: "Purchase-done",
    component: () => import("../components/Purchase/PurchaseDoneView.vue"),
  },
  {
    path: "/production/all",
    name: "Production-all",
    component: () => import("../components/Production/ProductionView.vue"),
  },
  {
    path: "/production/new",
    name: "Production-new",
    component: () => import("../components/Production/ProductionNewView.vue"),
  },
  {
    path: "/production/pending",
    name: "Production-pending",
    component: () => import("../components/Production/ProductionPendingView.vue"),
  },
  {
    path: "/production/done",
    name: "Production-done",
    component: () => import("../components/Production/ProductionDoneView.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../components/Plan/Orders.vue"),
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: () => import("../components/Transfer/TransferView.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
